<template>
  <section>
    <div
      v-if="loading"
      class="flex flex-col items-center justify-center mt-100"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <Basic
        v-if="article"
        :collection="collection"
        :article="article"
      />
      <van-empty
        v-else
      >
        <template #description>
          <span class="text-sm">{{ message }}</span>
        </template>
      </van-empty>
    </template>
  </section>
</template>

<script>
import share from '@/mixins/share'
import { mapState, mapGetters } from 'vuex'
import Basic from '@/components/articles/basic'
export default {
  name: 'PublicCmsViewer',
  components: { Basic },
  mixins: [share],
  data() {
    return {
      collection: null,
      loading: true,
      article: null,
      message: '无法加载专题信息'
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['db', '_']),
    ...mapState('Common/Config', ['cmsCollections']),
    ...mapGetters('Common/Credential', ['userInfo'])
  },
  async mounted() {
    this.collection = this.$route.params.collection ?? 'announcements'
    this.message = `无法加载${this.cmsCollections[this.collection]}信息`
    await Promise.all([this.fetch(), this.prepareWx()])
    if (this.article && !this.article.disableSharing) {
      let options = {
        title: this.article.title,
        desc: this.article.subtitle
      }
      if (this.article.image) {
        options.imgUrl = this.article.image
      }
      this.updateShareData(options)
    } else {
      this.disableShareMenu()
    }
    if (typeof Heti != 'undefined') {
      const heti = new Heti('.heti')
      heti.autoSpacing()
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        let result = await this.db
          .collection(this.collection)
          .where({
            _id: this._.eq(this.$route.params.id)
          })
          .get()
        // eslint-disable-next-line no-console
        console.warn('获取到相关专题', result)
        let article = result.data?.[0] ?? null
        if (article?.authorizationRequired) {
          let authenticated = ['已认证'].includes(this.userInfo?.status)
          if (!authenticated) {
            this.loading = false
            this.$dialog
              .confirm({
                title: '此内容仅向业主开放',
                message: '您尚未进行业主认证，是否立即进行业主认证？',
                theme: 'round-button',
                showCancelButton: true,
                cancelButtonText: '暂时不了',
                confirmButtonText: '业主认证'
              })
              .then(() => {
                this.$router.push({
                  name: 'public.authentication'
                })
              })
              .catch(() => {
                this.message = '此内容仅向业主开放'
              })

            return
          }
        }
        if(article?.archivedAt && this.$dayjs(article.archivedAt).isBefore(this.$dayjs())) {
          this.loading = false
          this.message = `此${this.cmsCollections[this.collection]}信息已归档，请通过意见建议模块预约调阅相关存档资料！`
          return
        }
        this.$set(this, 'article', article)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.result = null
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
